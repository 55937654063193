<template>
  <div>
    <div class="detail-wrapper">
      <el-row class="message">
        <el-col :span="20"><span>店铺: </span>{{shopData.shopName}}</el-col>
      </el-row>
      <el-row class="message">
        <el-col :span="8"><span>姓名: </span> {{shopData.name}}</el-col>
        <el-col :span="8" :offset="6"><span>性别: </span>{{shopData.sex === 'F' ? '女' : '男'}}</el-col>
      </el-row>
      <el-row class="message">
        <el-col :span="8"><span>提交人: </span> {{shopData.commitUser}}</el-col>
        <el-col :span="8" :offset="6"><span>提交日期: </span> {{shopData.updateTime}}</el-col>
        <!-- <el-col :span="8" :offset="6"><span>提交日期: </span> {{shopData.createTime}}</el-col> -->
      </el-row>
      <el-row class="message">
        <el-col :span="20"><span>身份证号: </span>{{shopData.idCard}}</el-col>
      </el-row>
      <el-row class="message" v-if="shopData">
        <el-col :span="20"><span>人像面: </span>
          <el-image :src="shopData.idcardFrontKey" alt="" :preview-src-list="srcList1" @click="previewSrc(shopData.idcardFrontKey,1)"></el-image>
        </el-col>
      </el-row>
      <el-row class="message" v-if="shopData">
        <el-col :span="20"><span>国徽面: </span>
          <el-image :src="shopData.idcardContraryKey" alt="" :preview-src-list="srcList2" @click="previewSrc(shopData.idcardContraryKey,2)"></el-image>
        </el-col>
      </el-row>
      <el-row class="message" v-if="shopData">
        <el-col :span="20"><span>健康证: </span>
          <el-image :src="shopData.healthCardKey" alt="" :preview-src-list="srcList3" @click="previewSrc(shopData.healthCardKey,3)"></el-image>
        </el-col>
      </el-row>
      <el-row class="message" v-if="shopData">
        <el-col :span="20"><span>自拍照: </span>
          <el-image :src="shopData.selfiePhotoKey" alt="" :preview-src-list="srcList4" @click="previewSrc(shopData.selfiePhotoKey,4)"></el-image>
        </el-col>
      </el-row>
    </div>
    <div class="rowBtn">
      <el-button @click="cancel" size="medium">取消</el-button>

    </div>
  </div>

</template>

<script>
import DLable from '../../../components/d-table'
import { mapState } from 'vuex'
export default {
  data(){ 
    return {
      srcList1:[],
      srcList2:[],
      srcList3:[],
      srcList4:[],
      shopData:{}
    }
  },
  computed: {

  },
  components: {
    DLable
  },
  created () {
    this.getRealnameInfo()
  },
  mounted () {
  },
  methods: {
    /**
     * 获取详情
     */
    getRealnameInfo(){
      this.$api.checkapi.getRealnameInfo(this.$route.query.id)
        .then(({code , data , msg})=>{
          if(code === 200){
            this.shopData = data
          }else{
            this.$message.error(msg);
          }
        })
    },
    /**
     * 取消
     */
    cancel(){
      this.$router.go(-1)
    },
    /**
     * 查看大图
     */
    previewSrc(src,type){
      this[`srcList${type}`] = [src]
    },
  },
  watch: {
  }
}
</script>
<style lang="scss" scoped>
.el-image{
  width:200px;
}
@import "src/pages/style/detail.scss";
</style>  
