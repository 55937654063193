<template>
  <div class="report" v-loading="loading">
    <div class="content">
      <d-search
        class="d-search"
        :searchData="searchData"
        @searchEvent="searchEvent"
        @openEvent="openEvent"
        @exportEvent="exportEvent"
      />

      <d-lable :columnData="columnData" :tableData="tableData"></d-lable>
      <d-paging :pager="pager" @change="getItemList" />
    </div>
  </div>
</template>

<script>
import DSearch from "../../../components/d-search";
import DLable from "../../../components/d-table";
import DPaging from "../../../components/d-paging";
import { options } from "../../../components/options";
import { onDownload } from "@/utils/utils";
export default {
  data() {
    return {
      pager: {
        count: 0,
        page: 1,
        rows: 10
      },
      loading: false,
      statusCount: {
        reject: null,
        pass: null,
        total: null
      },
      searchData: {
        searchItem: [
          {
            label: "状态",
            value: "status",
            type: "commonSelect",
            placeholder: "",
            options: []
          },
          {
            label: "选择店铺",
            value: "shopIdList",
            type: "linkageSelect",
            dateType: "month",
            placeholder: "",
            options: options,
            optionProps: {
              value: "name",
              label: "name",
              children: "childList"
            }
          }
        ],
        searchBtn: {
          queryBtn: true,
          exportBtn: true,
          isOpen: {
            type: true,
            open: true
          }
        },
        params: {
          status: null
        }
      },
      columnData: [
        { prop: "couponName", label: "券名称" },
        { prop: "cardNo", label: "会员卡号" },
        { prop: "couponFaceValue", label: "券面值" },
        { prop: "receiveTime", label: "领取时间" },
        { prop: "refundTime", label: "退款时间" },
        { prop: "refundShopName", label: "退款店铺" },
        { prop: "refundShopNo", label: "店铺号" },
        { prop: "status", label: "状态" },
        { prop: "operName", label: "操作人" }
        // {
        //   prop: "operate",
        //   label: "操作",
        //   type: {
        //     viewBtn: true
        //   }
        // }
      ],
      tableData: []
    };
  },
  computed: {},
  components: {
    DSearch,
    DLable,
    DPaging
  },
  created() {
    this.init();
  },
  mounted() {},
  methods: {
    /**
     * 搜索按钮点击
     */
    searchEvent() {
      this.getReturnCardList();
    },
    /**
     * 展开收起按钮
     */
    openEvent(status) {
      this.searchData.searchBtn.isOpen.open = status;
      if (!this.searchData.searchBtn.isOpen.open) {
        const name = {
          label: "券名称",
          value: "name",
          type: "commonInput",
          dateType: "month",
          placeholder: "请输入"
        };
        const item = {
          label: "核销时间",
          value: "commitTime",
          type: "scopeDate",
          startPlaceholder: "开始时间",
          endPlaceholder: "结束时间"
        };
        this.searchData.searchItem.push(name, item);
      } else {
        this.searchData.searchItem = this.searchData.searchItem.slice(0, 2);
      }
    },

    /**
     * 获取卡核销列表
     */
    getReturnCardList() {
      let shopNo = this.searchData.params.shopIdList
        ? this.searchData.params.shopIdList[
            this.searchData.params.shopIdList.length - 1
          ].match(/\(([^)]*)\)/)[1]
        : null;

      let refundTimeStart = this.searchData.params.commitTime
          ? this.searchData.params.commitTime[0]
          : null,
        refundTimeEnd = this.searchData.params.commitTime
          ? this.searchData.params.commitTime[1]
          : null;

      const obj = {
        pageNum: this.pager.page,
        pageSize: this.pager.rows,
        status: this.searchData.params.status,
        refundShopNo: shopNo,
        couponName: this.searchData.params.name,
        refundTimeStart,
        refundTimeEnd
      };
      this.$api.mentapi.getReturnCardList(obj).then(({ code, data, msg }) => {
        this.loading = false;
        if (code === 200) {
          this.pager.count = data.total;
          this.tableData = data.list.map(item => ({
            ...item,
            status: item.status == 0 ? "退款失败" : "退款成功"
          }));
        } else {
          this.$message.error(msg);
        }
      });
    },
    /**
     * 导出
     */
    exportEvent() {
      let shopNo = this.searchData.params.shopIdList
        ? this.searchData.params.shopIdList[
            this.searchData.params.shopIdList.length - 1
          ].match(/\(([^)]*)\)/)[1]
        : null;

      let refundTimeStart = this.searchData.params.commitTime
          ? this.searchData.params.commitTime[0]
          : null,
        refundTimeEnd = this.searchData.params.commitTime
          ? this.searchData.params.commitTime[1]
          : null;

      const obj = {
        pageNum: this.pager.page,
        pageSize: this.pager.rows,
        status: this.searchData.params.status,
        refundShopNo: shopNo,
        couponName: this.searchData.params.name,
        refundTimeStart,
        refundTimeEnd
      };
      this.$api.mentapi.exportReturnCard(obj).then(res => {
        let { headers, data } = res;
        onDownload(headers, data);
      });
    },
    /**
     * 查询请求
     */
    getItemList() {
      this.getReturnCardList();
    },
    /**
     * 获取选择店铺列表
     */
    getShopList() {
      this.$api.shopapi.getShopList().then(({ data, code }) => {
        if (code == 200) {
          this.childList = data.childList;
          const child = [
            {
              id: data.id,
              name: data.name,
              childList: data.childList
            }
          ];
          this.searchData.searchItem.forEach(item => {
            if (item.type === "linkageSelect") {
              item.options = child;
            }
          });
        }
      });
    },

    /**
     * 获取状态列表
     */
    getStatusList() {
      this.$api.mentapi
        .getSysDict({ dictType: "return_coupon" })
        .then(({ code, data }) => {
          this.searchData.searchItem[0].options = data.list.map(item => ({
            value: item.dictValue,
            label: item.dictLabel
          }));
        });
    },
    /**
     * init 初始化
     */
    init() {
      this.getShopList();
      this.getStatusList();
      this.getReturnCardList();
      // this.getCardVerifiedList()
    }
  },
  watch: {}
};
</script>
<style lang="scss" scoped>
.report {
  width: 100%;
  .content {
    .d-search {
      padding: 30px 0;
    }
    width: 100%;
    height: 100%;
    margin-top: 20px;
    box-sizing: border-box;
    background-color: #fff;
    padding: 0 30px;
    text-align: center;
  }
}
</style>
