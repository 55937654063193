<template>
  <div class="checkCard statistic-wrapper">
    <div class="content">
      <d-search class="d-search"
                :searchData="searchData"
                @searchEvent="searchEvent"
                @exportEvent="exportEvent" />

      <d-lable :columnData="columnData"
               :tableData="tableData"></d-lable>
      <d-paging :pager="pager"
                style="marginTop:50px;"
                @change="getItemList" />
    </div>
  </div>
</template>

<script>
import DSearch from "../../../components/d-search";
import DLable from "../../../components/d-table";
import DPaging from "../../../components/d-paging";
import { onDownload,getShopCode } from "@/utils/utils";
export default {
  data () {
    return {
      pager: {
        count: 0,
        page: 1,
        rows: 10
      },
      statusCount: {
        reject: null,
        pass: null,
        total: null
      },
      searchData: {
        searchItem: [
          {
            label: "姓名",
            value: "name",
            type: "commonInput",
            dateType: "month",
            placeholder: "请输入姓名"
          },
          {
            label: '预约日期',
            value: 'commitTime',
            type: 'scopeDate',
            startPlaceholder: "开始日期",
            endPlaceholder: "结束日期"
          },
          {
            label: '选择店铺',
            value: 'shopIdList',
            type: 'linkageSelect',
            dateType: 'month',
            placeholder: '',
            options: [],
            optionProps: {
              value: 'name',
              label: 'name',
              children: 'childList'
            }
          }, {
            label: '状态',
            value: 'status',
            type: 'commonSelect',
            placeholder: '',
            options: [
              {
                label: '已缴纳',
                value: 1,

              },
              {
                label: '已核验',
                value: 2
              },
              {
                label: '已退款',
                value: 3
              },
              {
                label: '已过期',
                value: 4
              }
            ],
          }
        ],
        searchBtn: {
          queryBtn: true,
          exportBtn: true,
        },
        params: {
          status: null
        }
      },
      columnData: [
        { prop: "username", label: "姓名" },
        { prop: "floorName", label: "楼层" },
        { prop: "shopCode", label: "店铺号" },
        { prop: "shopName", label: "店铺" },
        { prop: "checkTime", label: "核验时间" },
        { prop: "status", label: "状态" },
      ],
      tableData: []
    };
  },
  computed: {},
  components: {
    DSearch,
    DLable,
    DPaging
  },
  created () {
    this.init();
  },
  mounted () { },
  methods: {
    /**
     * 搜索按钮点击
     */
    searchEvent () {
      this.getItemList();
    },
    /**
     * 导出
     */
    exportEvent () {
      const obj = {
        pageNum: this.pager.page,
        pageSize: this.pager.rows,
        username: this.searchData.params.name,
        applyDateStart: this.searchData.params.commitTime && this.searchData.params.commitTime[0],
        applyDateEnd: this.searchData.params.commitTime && this.searchData.params.commitTime[1],
        shopCode: getShopCode(this.searchData.params.shopIdList),
        status: this.searchData.params.status
      }
      this.$api.mentapi.exportCashierApply(obj).then(res => {
        let { headers, data } = res;
        onDownload(headers, data);
      });
    },
    /**
     * 查询请求
     */
    getItemList () {
      const obj = {
        pageNum: this.pager.page,
        pageSize: this.pager.rows,
        username: this.searchData.params.name,
        applyDateStart: this.searchData.params.commitTime && this.searchData.params.commitTime[0],
        applyDateEnd: this.searchData.params.commitTime && this.searchData.params.commitTime[1],
        shopCode: getShopCode(this.searchData.params.shopIdList),
        status: this.searchData.params.status
      }
      this.$api.mentapi.getCashierApplyList(obj)
        .then((res) => {
          if (res.code === 200) {
            this.pager.count = res.data.total
            this.tableData = res.data.list.map(item => ({
              ...item,
              status: item.status == 1 ? '已缴纳' : item.status == 2 ? '已核验' : item.status == 3 ? '已退款' : '已过期'
            }))
          }
        })
    },

    /**
     * 获取选择店铺列表
     */
    getShopList () {
      this.$api.shopapi.getShopList()
        .then(({ data, code }) => {
          if (code == 200) {
            this.childList = data.childList
            const child = [{
              id: data.id,
              name: data.name,
              childList: data.childList
            }]
            this.searchData.searchItem.forEach(ele => {
              if (ele.type === 'linkageSelect') {
                ele.options = child
              }
            })
          }
        })
    },
    /**
     * init 初始化
     */
    init () {
      this.getItemList()
      this.getShopList()
    }
  },
  watch: {}
};
</script>
<style lang="scss" scoped>
@import "../style/statistic";
</style>
